/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.$ = window.jquery = jQuery;

// import 'cocoon-js';
import 'select2';
import 'bootstrap';
import lt from 'select2/src/js/select2/i18n/lt.js'
import flatpickr from "flatpickr";
import { Lithuanian } from "flatpickr/dist/l10n/lt.js"
import '@fortawesome/fontawesome-free/js/all';

import "flatpickr/dist/flatpickr.min.css"
import '../styles/selfservice.scss';

Rails.start();

$(function() {
  flatpickr(".datepicker", {
    locale: Lithuanian,
    allowInput: true,
    dateFormat: 'Y.m.d',
  });

  flatpickr(".datepicker-time", {
    locale: Lithuanian,
    allowInput: true,
    dateFormat: 'H:i:S',
    enableTime: true,
    noCalendar: true,
    minuteIncrement: 1,
    hourIncrement: 1,
    secondIncrement: 1,
    enableSeconds: true,
  });

  $(document).on('change', '#unlimited', function(e){
    const element = $('#potential_ownership_valid_until')

    if(e.target.checked){
      element.val('9999.12.31');
      element.prop('disabled', true);
    }else{
      element.prop('disabled', false);
      element.val('');
    }
  })


  $(document).on('submit', '.new_potential_ownership', function(e){
    e.preventDefault();
    const form = $('.new_potential_ownership');
    const name_field = $('#potential_ownership_television_program_name')
    const valid_from_field = $('#potential_ownership_valid_from')
    const valid_until_field = $('#potential_ownership_valid_until')

    let errors = [];

    if (name_field.val() === '') {
      name_field.addClass('border-danger');
      errors.push('name');
      
      if ($('.name-error').length == 0) {
        const errorMessageSpan = $('<span>');
        errorMessageSpan.text('Prašome įvesti pavadinimą.');
        errorMessageSpan.addClass('text-danger');
        errorMessageSpan.addClass('name-error')
        name_field.after(errorMessageSpan);
      }
    } else {
      name_field.removeClass('border-danger');
      $('.name-error').remove();
      var index = errors.indexOf('name');
      if (index !== -1) {
        errors.splice(index, 1);
      }
    }

    if (valid_from_field.val() === '') {
      valid_from_field.addClass('border-danger');
      errors.push('valid_from');
      
      if ($('.valid-from-error').length == 0) {
        const errorMessageSpan = $('<span>');
        errorMessageSpan.text('Prašome įvesti nuo kada galioja teisės.');
        errorMessageSpan.addClass('text-danger');
        errorMessageSpan.addClass('valid-from-error')
        valid_from_field.after(errorMessageSpan);
      }
    } else {
      valid_from_field.removeClass('border-danger');
      $('.valid-from-error').remove();
      var index = errors.indexOf('valid_from');
      if (index !== -1) {
        errors.splice(index, 1);
      }
    }

    if (valid_until_field.val() === '') {
      valid_until_field.addClass('border-danger');
      errors.push('valid_until');

      if ($('.valid-until-error').length == 0) {
        const errorMessageSpan = $('<span>');
        errorMessageSpan.text('Prašome įvesti iki kada galioja teisės.');
        errorMessageSpan.addClass('text-danger');
        errorMessageSpan.addClass('valid-until-error')
        valid_until_field.after(errorMessageSpan);
      }
    } else {
      valid_until_field.removeClass('border-danger');
      $('.valid-until-error').remove();
      var index = errors.indexOf('valid_until');
      if (index !== -1) {
        errors.splice(index, 1);
      }
    }

    if (errors.length == 0) {
      form[0].submit();
    }
    
  });
});

window.debounce = function (func, wait) {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}